import React, { useContext, useRef, useState } from "react";
import { Link } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";

const Footer = () => {
  const { device } = useContext(DocumentContext);

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  //

  const ajaxUrl = `//manage.kmail-lists.com/ajax/subscriptions/subscribe`;

  //

  const onSubmit = e => {
    e.preventDefault();

    if (submitting || submitted || !process.env.GATSBY_KLAVIYO_ID) {
      return false;
    }

    if (
      typeof window !== `undefined` &&
      window.location.href.includes(`localhost:8000`)
    ) {
      setSubmitting(true);

      setTimeout(() => {
        setSubmitting(false);
        setSubmitted(true);
      }, 3000);

      return false;
    }

    setSubmitting(true);

    const formData = new FormData(e.target);

    fetch(ajaxUrl, {
      body: formData,
      headers: {
        Accept: `application/json`,
        "Content-Type": `application/json`
      },
      method: `post`,
      mode: `no-cors`
    }).then(response => {
      // eslint-disable-next-line no-console
      console.log(`Sign up | `, response);

      setSubmitting(true);
      setSubmitted(true);
    });

    return false;
  };

  //

  return (
    <footer className="footer w-full relative pt-16 pb-8 bg-onyx text-white">
      {(device === `tablet` && (
        <div className="grid">
          <div className="grid-end-7">
            <p className="mb-4 b2">Keep in touch</p>

            <form
              className={`${
                submitted || submitting ? `opacity-50 pointer-events-none` : ``
              } transition-opacity w-full relative flex items-stretch border-t-white border-b-white`}
              onSubmit={onSubmit}
            >
              {process.env.GATSBY_KLAVIYO_ID && (
                <input
                  type="hidden"
                  name="g"
                  value={process.env.GATSBY_KLAVIYO_ID}
                />
              )}

              <input
                className="w-full relative pt-2 pb-2 b2"
                placeholder="Email address"
                readOnly={submitting || submitted}
                required
                type="email"
              />

              <input
                className="relative pr-3 pl-3 hover-underline b2 cursor-pointer"
                type="submit"
                value={submitted ? `Thanks!` : `Join`}
              />
            </form>

            <p className="mt-4 b2">
              Sign up to our newsletter for 10% off your first order.
            </p>
          </div>

          <ul className="grid-end-5 mt-8 pl-4">
            <li className="b2">
              <Link to="/terms" className="block hover-underline pt-1 pb-1">
                Terms and Conditions
              </Link>
            </li>

            <li className="b2">
              <Link to="/privacy" className="block hover-underline pt-1 pb-1">
                Privacy Policy
              </Link>
            </li>

            <li className="b2">
              <Link to="/contact" className="block hover-underline pt-1 pb-1">
                Contact Us
              </Link>
            </li>
          </ul>

          <div className="grid-end-7 mt-8">
            <p className="b2">
              Parlor Tea creates premium, organic, green tea products and
              experiences that facilitate body and mind wellness rituals for
              everyday living.
            </p>
          </div>

          <ul className="grid-end-5 mt-8 pl-4">
            <li className="b2">
              <Link to="/" className="block hover-underline pt-1 pb-1">
                Instagram
              </Link>
            </li>

            <li className="b2">
              <Link to="/" className="block hover-underline pt-1 pb-1">
                Pinterest
              </Link>
            </li>

            <li className="b2">
              <Link to="/" className="block hover-underline pt-1 pb-1">
                Facebook
              </Link>
            </li>

            <li className="b2">
              <Link to="/" className="block hover-underline pt-1 pb-1">
                Spotify
              </Link>
            </li>
          </ul>

          <p className="grid-end-12 xs:mt-12 b2">© Parlor Tea 2020</p>
        </div>
      )) || (
        <div className="grid">
          <div className="grid-end-4 xs:grid-end-12">
            <p className="mb-4 b2">Keep in touch</p>

            <form
              className={`${
                submitted || submitting ? `opacity-50 pointer-events-none` : ``
              } transition-opacity w-full relative flex items-stretch border-t-white border-b-white`}
              onSubmit={onSubmit}
            >
              {process.env.GATSBY_KLAVIYO_ID && (
                <input
                  type="hidden"
                  name="g"
                  value={process.env.GATSBY_KLAVIYO_ID}
                />
              )}

              <input
                className="w-full relative pt-2 pb-2 b2"
                placeholder="Email address"
                readOnly={submitting || submitted}
                required
                type="email"
              />

              <input
                className="relative pr-3 pl-3 hover-underline b2 cursor-pointer"
                type="submit"
                value={submitted ? `Thanks!` : `Join`}
              />
            </form>

            <p className="mt-4 b2">
              Sign up to our newsletter for 10% off your first order.
            </p>

            <p className="mt-32 xs:mt-12 b2">© Parlor Tea 2020</p>
          </div>

          <div className="grid-end-4 xs:grid-end-12 relative flex mt-8">
            <ul className="w-1/2 relative block pl-10 xs:pl-0">
              <li className="b2">
                <Link to="/terms" className="block hover-underline pt-1 pb-1">
                  Terms and Conditions
                </Link>
              </li>

              <li className="b2">
                <Link to="/privacy" className="block hover-underline pt-1 pb-1">
                  Privacy Policy
                </Link>
              </li>

              <li className="b2">
                <Link to="/contact" className="block hover-underline pt-1 pb-1">
                  Contact Us
                </Link>
              </li>
            </ul>

            <ul className="w-1/2 relative block pl-10 xs:pl-0">
              <li className="b2">
                <a
                  href="https://www.instagram.com/parlortea/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block hover-underline pt-1 pb-1"
                >
                  Instagram
                </a>
              </li>

              <li className="b2">
                <a
                  href="https://www.pinterest.com/parlortea/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block hover-underline pt-1 pb-1"
                >
                  Pinterest
                </a>
              </li>

              <li className="b2">
                <a
                  href="https://www.facebook.com/parlorteastore"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block hover-underline pt-1 pb-1"
                >
                  Facebook
                </a>
              </li>

              <li className="b2">
                <a
                  href="https://open.spotify.com/user/949y1vzu11dsjv04mj6jv33ds"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block hover-underline pt-1 pb-1"
                >
                  Spotify
                </a>
              </li>
            </ul>
          </div>

          <div className="grid-end-4 xs:grid-end-12 relative mt-8">
            <p className="b2">
              Parlor Tea creates premium, organic, green tea products and
              experiences that facilitate body and mind wellness rituals for
              everyday living.
            </p>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
